import { FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { PaymentPage } from 'Pages';

import { usePaymentGenerateLinkMutation, usePaymentInfoQuery } from 'Pages/Payment/api';
import { GenerateLinkForPayment } from 'Pages/Payment/Payment.entity';
import { globalError } from 'utils/globalError';
import { isSuccess } from 'utils/responseStatus';

export const PaymentScreen: FC = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const month = searchParams.get('month') || '';
  const year = searchParams.get('year') || '';
  const sum = searchParams.get('sum') || '';
  const email = searchParams.get('email') || '';

  const {
    data: paymentInfo,
    isError: isErrorInfo,
    isLoading: isLoadingInfo,
    refetch,
  } = usePaymentInfoQuery({ month, year, sum, accountId: id });
  const [paymentGenerateLink, { isLoading: isLoadingPayment }] = usePaymentGenerateLinkMutation();

  const handleSubmitPayment = (values: { email?: string }) => {
    if (!paymentInfo?.paymentSystems[0] || !id) return;

    const currentData: GenerateLinkForPayment = {
      email: values.email || '',
      paymentId: paymentInfo?.paymentSystems[0].paymentId,
      paymentSystemId: paymentInfo?.paymentSystems[0].paymentSystemId,
      toPaySum: paymentInfo?.paymentSystems[0].toPaySum,
      commissionSum: paymentInfo?.paymentSystems[0].commissionSum,
      sum: paymentInfo?.paymentSystems[0].sum,
    };

    paymentGenerateLink(currentData).then((data) => {
      if (isSuccess(data)) {
        data.data && document.location.replace(data.data);
      } else {
        globalError(data);
      }
    });
  };

  return (
    <PaymentPage
      isAcquiringForbidden={Boolean(paymentInfo?.isAcquiringForbidden)}
      paymentInfo={paymentInfo?.paymentSystems[0]}
      status="error"
      onSubmit={handleSubmitPayment}
      isLoadingPayment={isLoadingPayment}
      isLoadingInfo={isLoadingInfo}
      paymentError={isErrorInfo}
      email={email}
      onClickRetry={refetch}
    />
  );
};
