import { TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux';

import { configureStore } from '@reduxjs/toolkit';
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query';

import { omdApi } from './api';
import { globalSlice } from './globalReducer';
import { rtkQueryErrorLogger } from './rtkQueryErrorLoger';

export const store = configureStore({
  reducer: {
    [omdApi.reducerPath]: omdApi.reducer,
    globalSlice: globalSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(omdApi.middleware, rtkQueryErrorLogger),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
