import { enqueueSnackbar } from 'notistack';

export const globalError = (dataError: any) => {
  console.error('error', dataError);

  if (!dataError) {
    enqueueSnackbar('Произошла ошибка');
    return;
  }

  if (!dataError?.data) {
    enqueueSnackbar('Произошла ошибка');
    return;
  }

  if (!dataError?.data) {
    enqueueSnackbar('Произошла ошибка');
    return;
  }

  if (dataError?.data?.detail) {
    enqueueSnackbar(dataError?.data?.detail);
    return;
  }

  if (dataError?.data?.errors) {
    const errors = dataError?.data?.errors?.sum.join(',');
    enqueueSnackbar(errors);
    return;
  }
};

/* {
        "type": "https://tools.ietf.org/html/rfc7231#section-6.5.1",
        "title": "Bad Request",
        "status": 400,
        "detail": "Оплата через эквайринг невозможна. Возможна оплата по QR коду из квитанции",
        "traceId": "0HN911C239VRU:00000007"
    } */

/* {
          "type": "https://tools.ietf.org/html/rfc7231#section-6.5.1",
          "title": "One or more validation errors occurred.",
          "status": 400,
          "instance": "/api/v1/payments/generate-link-for-payment",
          "traceId": "0HN911C239VT1:00000001",
          "errors": [
"\u041D\u0435 \u0443\u043A\u0430\u0437\u0430\u043D \u0430\u0434\u0440\u0435\u0441
\u044D\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u043E\u0439 \u043F\u043E\u0447\u0442\u044B",
"\u041D\u0435\u0432\u0435\u0440\u043D\u044B\u0439 \u0430\u0434\u0440\
u0435\u0441 \u044D\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u043E\u0439 \u043F\u043E\u0447\u0442\u044B"
    ]
} */
